import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { SelectRole } from "@components/role/select-role";
import { SelectRoleSubjectProps } from "@components/role/select-role-utils";
import { getDisabledRoles } from "@utils/workspace-role-utils";

interface SelectCompanyRoleProps<T> extends SelectRoleSubjectProps<T> {
  /**
   * If true, it will allow all roles to be selected, otherwise it will
   * have restrictions based on the selected role.
   * @see https://faro01.atlassian.net/wiki/spaces/HBWEBDEV/pages/4473782411/Workspace+Role+Hierarchy
   */
  shouldAllowAllRolesSelection?: boolean;
}

/** Renders a dropdown to select company role */
export function SelectCompanyRole<
  T extends SphereDashboardAPITypes.IAssignmentCompanyRole
>({
  selectedRole,
  onChange,
  onResetSelection,
  isTableCell = false,
  placeholder,
  isRequired = true,
  shouldAllowAllRolesSelection = false,
}: SelectCompanyRoleProps<T>): JSX.Element {
  function onSelectRoleChange(role: CoreAPITypes.EUserCompanyRole): void {
    // TODO: Type assertion will be fixed in https://faro01.atlassian.net/browse/ST-1902
    onChange(role as T);
  }

  const roles: CoreAPITypes.EUserCompanyRole[] = [
    CoreAPITypes.EUserCompanyRole.companyExecutive,
    CoreAPITypes.EUserCompanyRole.companyViewer,
    CoreAPITypes.EUserCompanyRole.companyManager,
    CoreAPITypes.EUserCompanyRole.projectManager,
    CoreAPITypes.EUserCompanyRole.member,
  ];

  /**
   * Decides which roles to show disabled.
   * If shouldAllowAllRolesSelection is true, then no roles will be disabled.
   * Otherwise, it will show roles that are not assignable based on the selected role.
   * TODO: Type assertion will be fixed in https://faro01.atlassian.net/browse/ST-1902
   */
  const disabledRoles: SphereDashboardAPITypes.IAssignmentCompanyRole[] =
    shouldAllowAllRolesSelection
    ? []
    : getDisabledRoles(selectedRole) as SphereDashboardAPITypes.IAssignmentCompanyRole[];

  return (
    <SelectRole
      label="Workspace role"
      roles={roles}
      disabledRoles={disabledRoles}
      placeholder={placeholder}
      isTableCell={isTableCell}
      selectedRole={selectedRole as CoreAPITypes.EUserCompanyRole}
      onChange={onSelectRoleChange}
      onResetSelection={onResetSelection}
      link="workspaceRolesHelp"
      isRequired={isRequired}
    />
  );
}
