import { Grid, Stack } from "@mui/material";
import { useMemo, useState } from "react";
import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import {
  FaroDialog,
  SPACE_ELEMENTS_OF_MODAL,
} from "@components/common/dialog/faro-dialog";
import { useCompanyMembers } from "@hooks/use-company-members";
import { useCoreApiClient } from "src/api/use-core-api-client";
import { isValidEmail } from "@utils/member-utils";
import { MembersAutocomplete } from "@components/common/members-autocomplete/members-autocomplete";
import { projectMembersSelector } from "@store/projects/projects-selector";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  addProcessingProjects,
  removeProcessingProjects,
  setOneProjects,
} from "@store/projects/projects-slice";
import { RoleDisplayNames } from "@custom-types/member-types";
import { useAppParams } from "@router/router-helper";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useToast } from "@hooks/use-toast";
import { AutoCompleteMessage } from "@components/common/faro-text-field/faro-text-field-message";
import { ProjectEvents } from "@utils/track-event/track-event-list";
import { setOne } from "@store/members/members-slice";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { FaroButtonContained } from "@components/common/faro-button-contained";
import { BaseProjectProps } from "@custom-types/project-types";
import { SelectProjectRole } from "@components/role/select-project-role";
import {
  AutoCompleteMemberOption,
  AutoCompleteTeamOption,
} from "@components/common/members-autocomplete/members-autocomplete-types";
import {
  createMemberOption,
  createTeamOption,
} from "@components/common/members-autocomplete/members-autocomplete-utils";
import { teamsSelector } from "@store/teams/teams-selector";
import { convertToMemberTypes } from "@store/members/members-slice-utils";
import { TEAM_DISPLAY_NAME } from "@src/constants/team-constants";
import { isTeam } from "@utils/team-utils";
import { useTeamUtils } from "@hooks/use-team-utils";

/** Renders the invite member button and the functionality in project overview */
export function InviteMemberToProject({
  project,
}: BaseProjectProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { handleErrorWithToast } = useErrorContext();

  const companyMembers = useCompanyMembers();
  const projectMembers = useAppSelector(projectMembersSelector);
  const teams = useAppSelector(teamsSelector);
  const { isTeamName, getTeamIdByName } = useTeamUtils();

  const coreApiClient = useCoreApiClient();
  const { companyId, projectId } = useAppParams();
  const { showToast } = useToast();
  const { trackEvent } = useTrackEvent();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  /** Flag whether the input field in the autocomplete component has a valid email */
  const [isMemberInputValid, setIsMemberInputValid] = useState<boolean>(false);
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const [message, setMessage] = useState<AutoCompleteMessage | undefined>();

  const [selectedRole, setSelectedRole] =
    useState<SphereDashboardAPITypes.IAssignmentProjectRole>(
      CoreAPITypes.EUserProjectRole.viewer
    );

  /** Disable the `Send Invite` button if no user has been selected and the input is not a valid email */
  const shouldSendInviteDisable =
    !selectedMembers.length && !isMemberInputValid;

  /** Reforming company members data for stellar autocomplete component */
  const memberOptions: AutoCompleteMemberOption[] = useMemo(
    () =>
      companyMembers.map((member) => {
        const alreadyMemberInProject = projectMembers.find(
          (projectMember) => member.identity === projectMember.identity
        );
        const isAlreadyMemberInProject = !!alreadyMemberInProject;

        return createMemberOption({
          member,
          isDisabled: isAlreadyMemberInProject,
          disabledMessage: isAlreadyMemberInProject
            ? `Already in project ${
                alreadyMemberInProject?.role
                  ? `as ${RoleDisplayNames[alreadyMemberInProject?.role]}`
                  : ""
              }`
            : "",
        });
      }),
    [companyMembers, projectMembers]
  );

  /** Reforming team data for stellar autocomplete component */
  const teamOptions: AutoCompleteTeamOption[] = useMemo(
    () =>
      teams.map((team) => {
        const alreadyTeamInProject = projectMembers.find(
          (projectMember) => team.id === projectMember.identity
        );

        const isAlreadyTeamInProject = !!alreadyTeamInProject;

        return createTeamOption({
          team,
          isDisabled: isAlreadyTeamInProject,
          disabledMessage: isAlreadyTeamInProject
            ? `Already in project ${
                alreadyTeamInProject?.role
                  ? `as ${RoleDisplayNames[alreadyTeamInProject?.role]}`
                  : ""
              }`
            : "",
        });
      }),
    [projectMembers, teams]
  );

  /** Default setup when invite dialog open */
  function onOpenInviteDialog(): void {
    setMessage(undefined);
    setSelectedMembers([]);
    setIsDialogOpen(true);
  }

  function handleMemberSelect(members: string[]): void {
    const isMembersContainsEmail = members.some(isValidEmail);

    // Show hints about adding or selecting an email
    if (isMembersContainsEmail) {
      setMessage({
        type: "info",
        helperText:
          "Invitation to this project will be sent to the new email addresses",
      });
    } else {
      setMessage(undefined);
    }
    setSelectedMembers(members);
  }

  /** Submit members to be invited */
  async function handleConfirm(): Promise<void> {
    trackEvent({
      name: ProjectEvents.inviteMember,
      props: { numberOfMembers: selectedMembers.length, role: selectedRole },
    });

    // Close the dialog when the confirm button is clicked
    setIsDialogOpen(false);

    // Replacing team names by team ids to include the identity in payload
    const identitiesWithTeam = selectedMembers.map((identity) => {
      return getTeamIdByName(identity) ?? identity;
    });

    /** Exclude already existed members in the project */
    const validMembers = identitiesWithTeam.filter(
      (member) => !isAlreadyMemberInCurrentProject(member)
    );

    const payload: SphereDashboardAPITypes.IBulkMemberInvitePayload<"project"> =
      {
        assignments: [],
      };

    payload.assignments.push({
      role: selectedRole,
      identities: validMembers,
    });

    try {
      if (companyId && projectId && validMembers.length) {
        // Add the projectId to processing projects to start the loading spinner in the project
        dispatch(addProcessingProjects([projectId]));

        const response: SphereDashboardAPITypes.IBulkMemberInviteResponse<"project"> =
          await coreApiClient.V3.SDB.addMembersToProject(
            companyId,
            projectId,
            payload
          );

        // Add invited member to the store. response.data.members exist when the response is either success or warning
        if (response.data.members) {
          const projectWithUpdatedMembers = {
            ...project,
            members: response.data.members,
          };

          dispatch(setOneProjects(projectWithUpdatedMembers));

          const newMemberResponse = response.data.members.find(
            (allMembers) =>
              !projectMembers.some(
                (oldMembers) => oldMembers.identity === allMembers.identity
              )
          );

          if (newMemberResponse && !isTeam(newMemberResponse)) {
            const newMember = convertToMemberTypes(newMemberResponse);

            /**
             * The new member should be added to the member store as well.
             * We should use the setOne because we are updating the company role for all members
             * that are present in this list for a Project Role and we should have a Company Role.
             * If we update the role to project in the members store, we will not be able to remove
             * these members from the member page. The user needs to reload the page to have the company
             * role again.
             */
            dispatch(setOne(newMember));
          }
        }

        // Remove projectId from processing project to stop the loading spinner in the project
        dispatch(removeProcessingProjects([projectId]));

        // Show response messages
        const responseMessage = response.data.message;
        const additionalMessages: string[] = [];
        if (response.errorData) {
          response.errorData.details.failedInvitations?.forEach((error) => {
            additionalMessages.push(error.message);
          });

          response.errorData.details.failedPermissions?.forEach((error) => {
            additionalMessages.push(error.message);
          });
        }

        // TODO: Refactor to avoid repetition with InviteMemberToCompany: https://faro01.atlassian.net/browse/ST-858
        if (response.status === "success") {
          showToast({
            type: "success",
            message: "Invitations sent",
            description: additionalMessages,
          });
        } else if (response.status === "warning") {
          showToast({
            type: "warning",
            message: responseMessage,
            description: additionalMessages.map((message, index) => (
              <li key={index}>{message}</li>
            )),
          });
        } else if (response.status === "error") {
          showToast({
            type: "error",
            message: responseMessage,
            description: additionalMessages.map((message, index) => (
              <li key={index}>{message}</li>
            )),
          });
        }
      }
    } catch (error) {
      handleErrorWithToast({
        id: `inviteMember-${Date.now().toString()}`,
        title: "Cannot invite the member",
        error,
      });
    }
  }

  /** Return true if the new email is valid and it doesn't exist in the project */
  function isNewEmailValid(email: string): boolean {
    return isValidEmail(email) && !isAlreadyMemberInCurrentProject(email);
  }

  /** Return true if member already exist with same email in the project */
  function isAlreadyMemberInCurrentProject(email: string): boolean {
    return projectMembers.some(
      (member) => member.email === email || member.identity === email
    );
  }

  function onInputChange(value: string): void {
    const isValueExistOnMemberList = memberOptions.some(({ label }) =>
      label.includes(value)
    );

    if (!value) {
      // Removing message on clearing the search text
      setMessage(undefined);
      setIsMemberInputValid(false);
    } else if (!isValueExistOnMemberList && !isValidEmail(value)) {
      // Showing error message for new and invalid email
      setMessage({
        type: "error",
        helperText: "Doesn’t match any team, member.",
      });
      setIsMemberInputValid(false);
    } else if (isAlreadyMemberInCurrentProject(value)) {
      // Showing info message if member already exist in the project
      setMessage({
        type: "info",
        helperText:
          "A member with the same email already exists in the project",
      });
      setIsMemberInputValid(false);
    } else {
      if (isValidEmail(value)) {
        setIsMemberInputValid(true);
      }
      setMessage(undefined);
    }
  }

  return (
    <>
      <FaroButtonContained onClick={onOpenInviteDialog}>
        Invite members
      </FaroButtonContained>
      <FaroDialog
        title="Invite members to project"
        confirmText="Send Invite"
        open={isDialogOpen}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
        onConfirm={handleConfirm}
        isConfirmDisabled={shouldSendInviteDisable}
        onClose={() => setIsDialogOpen(false)}
      >
        <Grid maxWidth="100%" width="70vw">
          <Stack marginBottom={SPACE_ELEMENTS_OF_MODAL}>
            <SelectProjectRole
              selectedRole={selectedRole}
              onChange={setSelectedRole}
              isTableCell={false}
            />
          </Stack>
          <Stack>
            <MembersAutocomplete
              options={[...teamOptions, ...memberOptions]}
              handleChange={handleMemberSelect}
              onInputChange={onInputChange}
              validateNewOption={isNewEmailValid}
              isTeamName={isTeamName}
              message={message}
              labelTitle="Members"
              hasAutoFocus={true}
              placeHolder={`Email(s), ${TEAM_DISPLAY_NAME}(s) separated by comma`}
            />
          </Stack>
        </Grid>
      </FaroDialog>
    </>
  );
}
