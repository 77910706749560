import { useAppDispatch } from "@store/store-helper";
import { useLocation } from "react-router";
import { useEffect, useMemo, useState } from "react";
import { SearchTextField } from "@components/common/search-text-field";
import { Box } from "@mui/material";
import { useRouteMatch } from "@hooks/use-route-match";
import { useDebouncedSearchText } from "@hooks/use-debounced-search-text";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { setDebouncedSearchText, setIsDebouncingSearch } from "@store/ui/ui-slice";
import { SEARCHBAR_CONFIG, SearchbarErrorMsg } from "@components/header/searchbar-utils";
import { useMediaQueryList } from "@hooks/use-media-query";

/** Width in pixels of the search bar */
const SEARCHBAR_WIDTH = 200;
/** On narrow screens, reduce the width so that there's enough space e.g. for the breadcrumb menu. */
const SEARCHBAR_WIDTH_SMALL = 100;

/** Search bar component that automatically changes its context depending on the current route */
export function Searchbar(): JSX.Element | undefined {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const routeIdentifier = useRouteMatch();
  const [searchText, setSearchText] = useState<string>("");
  const { trackEvent } = useTrackEvent();
  const {debouncedSearchText, isDebouncingSearch}= useDebouncedSearchText({ searchText });
  const { isScreenXsAndSmall } = useMediaQueryList();

  // Whether the search value is valid
  const isValid: boolean = useMemo(() => {
    const validator = SEARCHBAR_CONFIG[routeIdentifier].validator;
    return validator ? validator(debouncedSearchText) : true;
  }, [debouncedSearchText, routeIdentifier]);

  // Updates the debounced search text value in the redux store for every change
  useEffect(() => {
    dispatch(setDebouncedSearchText(debouncedSearchText));
  }, [debouncedSearchText, dispatch]);

  // Updates the isDebouncingSearch flag value in the redux store for every change
  useEffect(() => {
    dispatch(setIsDebouncingSearch(isDebouncingSearch));
  }, [dispatch, isDebouncingSearch]);

  // Resets the searchText when the route/page changes.
  useEffect(() => {
    if (pathname) {
      setSearchText("");
    }
  }, [pathname]);

  /**
   * Tracks the search event if:
   * - The log event params are defined and
   * - The debounced search value changes and
   * - The user is not typing.
   */
  useEffect(() => {
    if (
      SEARCHBAR_CONFIG[routeIdentifier].logEventParams &&
      debouncedSearchText.length > 0 &&
      !isDebouncingSearch
    ) {
      trackEvent(SEARCHBAR_CONFIG[routeIdentifier].logEventParams);
    }
  }, [debouncedSearchText.length, isDebouncingSearch, routeIdentifier, trackEvent]);

  if (!SEARCHBAR_CONFIG[routeIdentifier].isSearchAllowed) {
    return;
  }

  const placeholder = isScreenXsAndSmall || !SEARCHBAR_CONFIG[routeIdentifier].placeholder ?
    "Search" :
    SEARCHBAR_CONFIG[routeIdentifier].placeholder;
  const width = isScreenXsAndSmall ? SEARCHBAR_WIDTH_SMALL : SEARCHBAR_WIDTH;

  return (
    <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
      {/* Error helper text for screens larger than medium breakpoint */}
      {!isScreenXsAndSmall && !isValid && (
        <Box
          sx={{
            maxWidth: `${width}px`,
            mr: "10px",
          }}
        >
          <SearchbarErrorMsg errorMessage={SEARCHBAR_CONFIG[routeIdentifier].errorMessage}/>
        </Box>
      )}
      {/* Search field */}
      <Box
        component="div"
        sx={{
          minWidth: "100px",
          width: `${width}px`,
        }}
      >
        <SearchTextField
          placeholder={placeholder}
          searchText={searchText}
          onClearClicked={() => setSearchText("")}
          onChangeSearch={(searchText) => setSearchText(searchText === " " ? "" : searchText)}
          isError={!isValid}
        />
        {/* Error helper text for screens equal or smaller than medium breakpoint */}
        {isScreenXsAndSmall && !isValid && <SearchbarErrorMsg errorMessage={SEARCHBAR_CONFIG[routeIdentifier].errorMessage}/>}
      </Box>
    </Box>
  );
}
