import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { formatUserRoleType } from "@utils/data-display";
import { FaroMenuItem } from "@components/common/faro-select/faro-menu-item";
import { FaroTextMenuButton } from "@components/common/faro-text-menu-button";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { setBulkActionName, updateOne } from "@store/table/table-slice";
import { useEffect, useState } from "react";
import { BulkSuccessDialog } from "@components/common/faro-table/bulk-dialog/bulk-success-dialog";
import { useChangeRoleBulkDialogContent } from "@components/table/members/bulk-actions/bulk-change-role-helper";
import {
  bulkActionNameSelector,
  selectedEntitiesSelector,
  shouldShowSuccessDialogSelector,
} from "@store/table/table-selector";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { BulkActionEvents } from "@utils/track-event/track-event-list";
import { useCoreApiClient } from "@api/use-core-api-client";
import {
  AssignableUpdateCompanyMemberRole,
  MemberTypes,
} from "@custom-types/member-types";
import { currentUserSelector } from "@store/user/user-selector";
import { updateMemberRoleInWorkspace } from "@store/members/members-slice-thunk";
import { FaroBulkDialog } from "@components/common/faro-table/bulk-dialog/bulk-dialog";

/** Button allowing to change role of members in company */
export function BulkChangeCompanyRole({
  companyId,
}: BaseCompanyIdProps): JSX.Element {
  const [shouldShowDialog, setShouldShowDialog] = useState<boolean>(false);
  const [newRole, setNewRole] =
    useState<SphereDashboardAPITypes.UpdateCompanyMemberRole>(
      CoreAPITypes.EUserCompanyRole.member
    );

  const currentUser = useAppSelector(currentUserSelector);
  const bulkActionName = useAppSelector(bulkActionNameSelector);
  const shouldShowSuccessDialog = useAppSelector(
    shouldShowSuccessDialogSelector
  );
  const selectedEntities = useAppSelector(selectedEntitiesSelector("members"));

  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();

  const { bulkDialogContent, successDialogContent } =
    useChangeRoleBulkDialogContent({ subjectType: "workspace", newRole });

  // Closes the bulk dialog when the bulk action is successful to show the success dialog
  useEffect(() => {
    if (shouldShowSuccessDialog) {
      setShouldShowDialog(false);
    }
  }, [shouldShowSuccessDialog]);

  function onNewRoleClicked(event: React.MouseEvent<HTMLLIElement>): void {
    dispatch(setBulkActionName("changeRole"));

    if (currentUser) {
      dispatch(
        updateOne({
          id: currentUser.identity,
          changes: {
            status: "not-allowed",
            message: "You cannot change the role of yourself.",
          },
        })
      );
    }

    // TODO: Check whether it is possible to remove type-casting: https://faro01.atlassian.net/browse/ST-1348
    setNewRole(
      event.currentTarget.dataset
        .myValue as SphereDashboardAPITypes.UpdateCompanyMemberRole
    );
    setShouldShowDialog(true);
  }

  return (
    <>
      <FaroTextMenuButton
        buttonTitle="Change role"
        tooltipText="Change role in workspace"
        buttonSx={() => {
          return { fontWeight: 600 };
        }}
      >
        {Object.values(AssignableUpdateCompanyMemberRole).map(
          (role) =>
            role !== CoreAPITypes.EUserCompanyRole.member && (
              <FaroMenuItem
                data-my-value={role}
                key={role}
                value={role}
                onClick={onNewRoleClicked}
              >
                {formatUserRoleType(role)}
              </FaroMenuItem>
            )
        )}
      </FaroTextMenuButton>

      {/* Checking for bulkActionName to make sure the dialog of another bulk action is not opened by mistake */}
      {bulkActionName === "changeRole" && shouldShowSuccessDialog && (
        <BulkSuccessDialog dialogContent={successDialogContent} />
      )}

      {bulkActionName === "changeRole" && shouldShowDialog && (
        <FaroBulkDialog
          dialogContent={bulkDialogContent}
          onClose={() => setShouldShowDialog(false)}
          trackingEvent={BulkActionEvents.bulkChangeRole}
          bulkActionCallback={(member: MemberTypes) =>
            dispatch(
              updateMemberRoleInWorkspace({
                coreApiClient,
                companyId,
                role: newRole,
                identity: member.identity,
              })
            ).unwrap()
          }
          selectedEntities={selectedEntities}
          uniqueIdKey="identity"
          tableDataType="members"
        />
      )}
    </>
  );
}
