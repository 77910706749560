import { useCallback } from "react";
import { MultiUploadedFileResponse } from "@custom-types/file-upload-types";
import {
  createMutationAddArea,
  CreateMutationAddPhotogrammetryInput,
  MutationAddPhotogrammetryInput,
  UploadedFileInfo,
} from "@faro-lotv/service-wires";
import {
  IElement,
  IElementType,
  IElementTypeHint,
} from "@faro-lotv/ielement-types";
import { SdbProject } from "@custom-types/project-types";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { useAppSelector } from "@store/store-helper";
import { currentUserSelector } from "@store/user/user-selector";
import { usePhotogrammetryApiClient } from "@api/photogrammetry-api/use-photogrammetry-api-client";
import { getImageDimensions } from "@pages/project-details/project-data-management/photogrammetry-data/photogrammetry-data-utils";
import { generateGUID } from "@faro-lotv/foundation";


export type MutationParams = {
  /** The files selected by the user */
  selectedFiles: FileList | File[];
  /** The selected sheet */
  selectedSheet: IElement | null;
  /** The response from the upload */
  uploadedResponse: MultiUploadedFileResponse;
};

type ReturnFunction = (params: MutationParams) => Promise<void>;

/**
 * The hook to prepare and send a mutation for adding photogrammetry input data to a project.
 * It processes selected files, retrieves necessary project elements, constructs the mutation,
 * and triggers a photogrammetry task using the photogrammetry API client.
 *
 * @param project - The project to which the mutation is sent.
 */
export function useSendMutation(project: SdbProject): ReturnFunction {
  const projectAPI = useProjectApiClient({ projectId: project.id });
  const currentUser = useAppSelector(currentUserSelector);
  const photogrammetryApiClient = usePhotogrammetryApiClient({
    projectId: project.id,
  });

  return useCallback(
    async ({
      selectedFiles,
      selectedSheet,
      uploadedResponse,
    }: MutationParams): Promise<void> => {
      if (!currentUser?.id) {
        throw new Error("Missing required userId for photogrammetry task");
      }

      const fileMap = new Map<string, File>();
      Array.from(selectedFiles).forEach((file) => {
        fileMap.set(file.name, file);
      });

      const uploadedFileEntries = await Promise.all(
        uploadedResponse.successful.map(
          async (uploaded): Promise<UploadedFileInfo> => {
            const date = new Date();
            const localFile = fileMap.get(uploaded.fileName);

            let dimensions = { width: 0, height: 0 };
            if (localFile) {
              dimensions = await getImageDimensions(localFile);
            }

            return {
              uri: uploaded.downloadUrl,
              name: uploaded.fileName,
              pixelWidth: dimensions.width,
              pixelHeight: dimensions.height,
              duration: null,
              FileName: uploaded.fileName,
              fileSize: uploaded.fileSize,
              md5: uploaded.md5,
              createdAt: date.toUTCString(),
            };
          }
        )
      );
      const root = await projectAPI.getRootIElement();
      let selectedSheetID = null;
      if (selectedSheet === null) {
        // If no sheet is selected, get the first area section, this is temporary,
        // until we move to the staging capture tree (https://faro01.atlassian.net/browse/SMETA-1510)
        const areaSections = await projectAPI.getIElements({
          types: [IElementType.section],
          typeHints: [IElementTypeHint.area],
        });
        if (areaSections.page[0] !== undefined) {
          selectedSheetID = areaSections.page[0].id;
        }
      }
      let timeSeriesID = null;
      if (selectedSheetID != null) {
        const timeSeriesQuery = await projectAPI.getIElements({
          types: [IElementType.timeSeries],
          parentIds: [selectedSheetID],
        });

        if (timeSeriesQuery.page.length !== 0) {
          timeSeriesID = timeSeriesQuery.page[0].id;
        }
      }

      /** Handle the case where there is no area section */
      if (timeSeriesID === null && selectedSheetID === null) {
        const slideContainerResponse = await projectAPI.getIElements({
          types: [IElementType.group],
          typeHints: [IElementTypeHint.slideContainer],
        });

        if (slideContainerResponse.page.length === 0) {
          throw new Error("Unable to create an area section");
        }

        const slideContainer = slideContainerResponse.page[0];
        const newAreaMutation = createMutationAddArea({
          id: generateGUID(),
          rootId: slideContainer.rootId,
          groupId: slideContainer.id,
          name: "Photogrammetry Data",
        });

        try {
          // Create a new area in the project if there are none
          await projectAPI.applyMutations([newAreaMutation]);
          selectedSheetID = newAreaMutation.newElement.id;
        } catch (error) {
          throw new Error("Unable to create an area section");
        }
      }

      const mutation: MutationAddPhotogrammetryInput =
        CreateMutationAddPhotogrammetryInput({
          images2d: uploadedFileEntries,
          images360: [],
          videos2d: [],
          videos360: [],
          rootId: root.id,
          targetSheetId: selectedSheetID,
          timeseriesId: timeSeriesID,
        });


      if (!mutation.dataset?.id) {
        throw new Error("Missing datasetId for photogrammetry task");
      }

      await projectAPI.applyMutations([mutation]);

      const payload = {
        projectId: project.id,
        userId: currentUser?.id,
        datasetId: mutation.dataset?.id,
      };

      await photogrammetryApiClient.triggerPhotogrammetryTask(payload);
    },
    [currentUser?.id, projectAPI, project.id, photogrammetryApiClient]
  );
}
