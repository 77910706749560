import {
  AllSplashScreens,
  changeAllSdbSplashScreensStatus,
  createSplashScreenPayload,
  parseFueFlags,
} from "@store/user/user-slice-helper";
import { SplashScreenSdbTopic } from "@components/splash-screen/splash-screen-data";
import { SplashScreenViewStatus } from "@components/splash-screen/splash-screen-utils";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import {
  BaseCoreApiClientProps,
  CoreApiWithCompanyIdProps,
} from "@store/store-types";
import { RootState } from "@store/store-helper";
import { BaseUserProps } from "@custom-types/member-types";
import { getCurrentUserProfileInfos } from "@api/core-api-utils";

/**
 * State of User members
 */
export interface UserState {
  /** Current logged in user */
  currentUser: SphereDashboardAPITypes.ICompanyMemberBase | null;

  /** All the splash screens with their view status */
  splashScreens: AllSplashScreens;

  /**
   * Extra information about current logged in user.
   * For details like name, email, etc. use currentUser.
   * This is only used for information like login method, SSO provider, etc.
   */
  loggedInUser: SphereDashboardAPITypes.IGetLoggedInUserResponse | null;

  /**
   * If the user requests to update their email, this will be set to the new email.
   * This is used because email is not changed automatically and should be validated first.
   * But if we don't show the new email in the current session, the user might think that the email was not changed.
   */
  updatedEmail: undefined | string;

  /** Collects all the fetching properties for this slice */
  fetching: {
    /** Flag whether the current user is being fetched from the backend */
    isFetchingCurrentUser: boolean;

    /** Flag whether the logged in user is being fetched from the backend */
    isFetchingLoggedInUser: boolean;

    /** Flag whether the Fue flags are being set to the backend */
    isSettingFueFlags: boolean;

    /** Flag whether the user clicked on logging out, and the logout route is being called */
    isLoggingOut: boolean;
  };
}

const initialState: UserState = {
  currentUser: null,
  loggedInUser: null,

  // At initial state, all splash screens are unseen. The status will update based on the fetched data from backend
  splashScreens: { sdb: changeAllSdbSplashScreensStatus("unseen"), other: {} },

  updatedEmail: undefined,
  fetching: {
    isFetchingCurrentUser: false,
    isFetchingLoggedInUser: false,
    isSettingFueFlags: false,
    isLoggingOut: false,
  },
};

/** Fetches information of current logged in user */
export const fetchCurrentUser = createAsyncThunk<
  SphereDashboardAPITypes.ICompanyMemberBase,
  CoreApiWithCompanyIdProps,
  { state: RootState }
>("user/fetchCurrentUser", async ({ coreApiClient, companyId }, { getState }) => {
  const {
    app: { isDevModeEnabled },
  } = getState();

  if (!companyId) {
    throw new Error("No companyId was given to fetchCurrentUser");
  }

  try {
    const data = await getCurrentUserProfileInfos(
      coreApiClient, companyId, isDevModeEnabled
    );
    return data;
  } catch (error) {
    throw new Error(getErrorDisplayMarkup(error));
  }
});

/**
 * Set the FueFlags. The provided payload will be set as FUE flags directly.
 * It will not be added to the existed FUE flags in backend
 */
export const setFueFlags = createAsyncThunk<
  {
    updatedValue: string;
  },
  BaseCoreApiClientProps,
  { state: RootState }
>("user/setFueFlags", async ({ coreApiClient }, { getState }) => {
  const {
    user: { splashScreens },
  } = getState();
  const fueFlags = createSplashScreenPayload(splashScreens);

  try {
    return await coreApiClient.V3.SDB.setUserFueFlags(fueFlags);
  } catch (error) {
    throw new Error(getErrorDisplayMarkup(error));
  }
});

/** Logs out the current user by calling the logout route. Does not take care of redirection afterwards */
export const logoutUser = createAsyncThunk<void, BaseCoreApiClientProps>(
  "user/logoutUser",
  async ({ coreApiClient }): Promise<void> => {
    try {
      await coreApiClient.V3.SDB.logoutUser();
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/** Props to update the current user profile */
type UpdateCurrentUserProfileProps = BaseUserProps &
  BaseCoreApiClientProps &
  Pick<SphereDashboardAPITypes.SetMetaUserProfilePayload, "password">;

/** Fetches information of current logged in user */
export const updateCurrentUserProfile = createAsyncThunk<
  SphereDashboardAPITypes.IUserDetails,
  UpdateCurrentUserProfileProps
>(
  "user/updateCurrentUserProfile",
  async ({ coreApiClient, user, password }) => {
    const { name, lastName, userId, mailAddress, profileImageUrl } = user;

    if (!name || !lastName || !mailAddress || !userId) {
      throw new Error("The information to update profile is incomplete");
    }
    try {
      const data = await coreApiClient.V3.SDB.setMetaUserProfile({
        name,
        lastName,
        userId,
        mailAddress,
        profileImageUrl,
        password,
      });
      return data;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/** Fetches information of current logged in user, extra details like login method, SSO provider */
export const getLoggedInUser = createAsyncThunk<
  SphereDashboardAPITypes.IGetLoggedInUserResponse,
  BaseCoreApiClientProps,
  {
    state: RootState;
  }
>(
  "user/getLoggedInUser",
  async ({ coreApiClient }) => {
    try {
      const response = await coreApiClient.V3.SDB.getLoggedInUser();
      // We need the full response to remind users to verify email address.
      // To determine unconfirmed email status and code property in response will be used.
      return response;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/**
 * Slice to access state of loaded user members
 */
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentUser(
      state,
      action: PayloadAction<SphereDashboardAPITypes.ICompanyMemberBase>
    ) {
      state.currentUser = action.payload;
    },

    setUpdatedEmail(state, action: PayloadAction<string>) {
      state.updatedEmail = action.payload;
    },

    /** Set the status of the splashscreen topic to the provided one */
    setSplashScreenStatus(
      state,
      action: PayloadAction<{
        topic: SplashScreenSdbTopic;
        status: SplashScreenViewStatus;
      }>
    ) {
      state.splashScreens.sdb[action.payload.topic] = action.payload.status;
    },

    /** Reset the status of all the splashscreen topics to unseen */
    resetSplashScreenStatus(state) {
      state.splashScreens.sdb = changeAllSdbSplashScreensStatus("unseen");
    },

    resetUserState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCurrentUser.pending, (state, action) => {
        state.fetching.isFetchingCurrentUser = true;
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.fetching.isFetchingCurrentUser = false;
        state.currentUser = action.payload;
      })
      .addCase(fetchCurrentUser.rejected, (state, action) => {
        state.fetching.isFetchingCurrentUser = false;
      })

      .addCase(getLoggedInUser.pending, (state, action) => {
        state.fetching.isFetchingLoggedInUser = true;
      })
      .addCase(getLoggedInUser.fulfilled, (state, action) => {
        state.loggedInUser = action.payload;
        state.fetching.isFetchingLoggedInUser = false;

        state.splashScreens = parseFueFlags(
          state.splashScreens,
          action.payload.data.fueFlags
        );
      })
      .addCase(getLoggedInUser.rejected, (state, action) => {
        state.fetching.isFetchingLoggedInUser = false;
      })

      .addCase(setFueFlags.pending, (state, action) => {
        state.fetching.isSettingFueFlags = true;
      })
      .addCase(setFueFlags.fulfilled, (state, action) => {
        state.fetching.isSettingFueFlags = false;

        state.splashScreens = parseFueFlags(
          state.splashScreens,
          action.payload.updatedValue
        );
      })
      .addCase(setFueFlags.rejected, (state, action) => {
        state.fetching.isSettingFueFlags = false;
        throw new Error(getErrorDisplayMarkup(action.error));
      })

      .addCase(updateCurrentUserProfile.fulfilled, (state, action) => {
        if (state.currentUser) {
          state.currentUser = {
            ...state.currentUser,
            email: action.payload.mailAddress,
            firstName: action.payload.name,
            lastName: action.payload.lastName,
            thumbnailUrl: action.payload.profileImageUrl,
          };
        }
        if (state.loggedInUser) {
          state.loggedInUser.data = action.payload;
        }
      })
      .addCase(updateCurrentUserProfile.rejected, (state, action) => {
        throw new Error(getErrorDisplayMarkup(action.error));
      })

      .addCase(logoutUser.pending, (state, action) => {
        state.fetching.isLoggingOut = true;
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.fetching.isLoggingOut = false;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.fetching.isLoggingOut = false;
      });
  },
});

export const {
  setCurrentUser,
  setUpdatedEmail,
  setSplashScreenStatus,
  resetSplashScreenStatus,
  resetUserState,
} = userSlice.actions;

export const userReducer = userSlice.reducer;
