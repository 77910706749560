import { ProjectApi } from "@api/project-api/project-api";
import { assert, GUID } from "@faro-lotv/foundation";
import { CaptureApiClient, CaptureTreeEntity, createMutationDeleteCaptureTreeEntities, RegistrationState } from "@faro-lotv/service-wires";
import { fetchAllCaptureTreeRevisions } from "@store/capture-tree/capture-tree-thunks";
import { AppDispatch } from "@store/store-helper";
import { isElsScanFileUploadTaskContext } from "@custom-types/file-upload-type-guards";
import { ReturnFunction } from "@hooks/data-management/use-cancel-revision";
import { FileUploadTask } from "@custom-types/file-upload-types";
import { UploadManagerInterface } from "@custom-types/upload-manager-types";
import { isTaskInProgress } from "@hooks/upload-tasks/upload-tasks-utils";
import { LogEventParams } from "@utils/track-event/use-track-event";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { changeSummaryForTracking, RevisionChangeSummary } from "@utils/capture-tree/capture-tree-changes";
import { CaptureTreeRevision } from "@custom-types/capture-tree/capture-tree-types";
import { WorkflowState } from "@pages/project-details/project-data-management/data-management-types";

/** Delete entities from Capture Tree, using a mutation. */
export async function deleteCaptureTreeEntities(projectApiClient: ProjectApi, entities: CaptureTreeEntity[]): Promise<void> {
  assert(entities.length > 0, "No entities to delete.");
  // Remove scans from capture tree.
  const mutation = createMutationDeleteCaptureTreeEntities(
    entities.map((entity) => entity.id),
    CaptureApiClient.dashboard
  );
  await projectApiClient.applyMutations([mutation]);
}

/**
 * Find all relevant revisions for the current project.
 * Returns all revisions that are merged and created by the considered clients.
 * If a revision ID is provided, all revisions before this revision are removed.
 */
export async function getClientMergedRevisions(
  dispatch: AppDispatch,
  projectApiClient: ProjectApi,
  skipUntilRevisionId: GUID | null = null
): Promise<CaptureTreeRevision[]> {
  const allRevisions = await dispatch(fetchAllCaptureTreeRevisions({ projectApiClient })).unwrap();
  // Sort by creation date, newest first.
  allRevisions.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

  // Remove first n revisions until revision x.
  if (skipUntilRevisionId) {
    for (let i = 0; i < allRevisions.length; i++) {
      if (allRevisions[i].id === skipUntilRevisionId) {
        allRevisions.splice(0, i);
        // Example:
        // allRevisions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        // skipUntilRevisionId = 5
        // => allRevisions = [5, 6, 7, 8, 9, 10]
        break;
      }
    }
  }

  const consideredClients = [CaptureApiClient.dashboard, CaptureApiClient.stream, CaptureApiClient.scene];
  return allRevisions.filter((revision) =>
    revision.state === RegistrationState.merged &&
    revision.createdByClient &&
    consideredClients.includes(revision.createdByClient)
  );
};

/**
 * Cancels the upload step and removes the tasks from the store.
 */
export async function cancelUploads(
  captureTreeRevisionId: GUID,
  projectApiClient: ProjectApi,
  cancelRevision: ReturnFunction,
  uploadTasks: FileUploadTask[],
  uploadManager: UploadManagerInterface,
  trackEvent: (params: LogEventParams) => void
): Promise<void> {
  // If the user has opened the dialog shortly before the upload has finished, it might now be too late to cancel.
  // Canceling the revision first makes sure that useAddScansToRevisionAndMerge() can detect it.
  const revision = await projectApiClient.getRegistrationRevision(captureTreeRevisionId);

  if (revision.state === RegistrationState.started) {
    await cancelRevision(projectApiClient, captureTreeRevisionId);
  }

  // We also need to cancel aborted and successful tasks, otherwise they would stay on the page.
  // E.g. smaller scans might be successful before the user cancels the upload.
  const [tasksCanceledTotal, tasksCanceledInProgress] = cleanupUploadTasks(uploadTasks, uploadManager);

  trackEvent({
    name: DataManagementEvents.cancelImport,
    props: {
      workflowState: "upload",
      tasksCanceledTotal,
      tasksCanceledInProgress,
      captureTreeRevisionId,
      revisionState: revision.state,
    },
  });
}

export async function cancelRegistrationsAndDraftRevision(
  projectApiClient: ProjectApi,
  workflowState: WorkflowState,
  revisions: CaptureTreeRevision[],
  openDraftRevision: CaptureTreeRevision,
  changeSummary: RevisionChangeSummary,
  uploadTasks: FileUploadTask[],
  uploadManager: UploadManagerInterface,
  cancelRevision: ReturnFunction,
  trackEvent: (params: LogEventParams) => void
): Promise<void> {
  // Cancel any revisions created by the registration backend, to stop the registration workers from working on obsolete data.
  const registrationRevisionsToCancel = revisions.filter((revision) =>
    revision.state !== RegistrationState.merged && revision.state !== RegistrationState.canceled &&
    revision.id !== openDraftRevision.id &&
    revision.createdByClient === CaptureApiClient.registrationBackend
  );
  for (const revision of registrationRevisionsToCancel) {
    await cancelRevision(projectApiClient, revision.id);
  }

  // ...and finally cancel the draft revision.
  // This should be done last, otherwise ProjectAPI might automatically create a new draft revision
  // if there are still other open revisions.
  await cancelRevision(projectApiClient, openDraftRevision.id);

  // We also need to remove upload tasks, otherwise they would stay on the page.
  cleanupUploadTasks(uploadTasks, uploadManager);

  // Amplitude event.
  trackEvent({
    name: DataManagementEvents.cancelImport,
    props: {
      isCancelDraftRevision: true,
      workflowState,
      changeSummary: changeSummaryForTracking(changeSummary),
    },
  });
}

/**
 * Cancels the upload tasks and removes them from the store.
 */
export function cleanupUploadTasks(
  uploadTasks: FileUploadTask[],
  uploadManager: UploadManagerInterface
): [number, number] {
  const tasksToCancel = uploadTasks.filter((task) => isElsScanFileUploadTaskContext(task.context));
  const tasksToCancelInProgress = tasksToCancel.filter((task) => isTaskInProgress(task));

  for (const task of tasksToCancel) {
    // Cancel upload (if in progress), and remove from store (always).
    uploadManager.cancelFileUpload(task.id, true);
  }

  // Return the number of canceled tasks. For Amplitude event.
  return [tasksToCancel.length, tasksToCancelInProgress.length];
}
