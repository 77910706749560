import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  setIsAlphaTestingEnabled,
  setIsBetaTestingEnabled,
  setIsDevModeEnabled,
} from "@store/app/app-slice";
import { useSearchParams } from "react-router-dom";
import { IReactChildrenOnly } from "@custom-types/types";
import { QueryParams } from "@router/route-params";
import { currentUserSelector } from "@store/user/user-selector";
import { isInternalUser } from "@utils/user-utils";

/**
 * Component that validates whether a query param exist or not all over the app
 * For example, it can be used to check whether "alpha" or "beta" features are enable or not
 */
export function QueryParamsLoader({
  children,
}: IReactChildrenOnly): JSX.Element {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const currentUser = useAppSelector(currentUserSelector);
  const isUserInternal: boolean | null = useMemo(
    () => currentUser && isInternalUser(currentUser.email),
    [currentUser]
  );

  const enableBetaParam = searchParams.get(QueryParams.enableBeta);
  const enableAlphaParam = searchParams.get(QueryParams.enableAlpha);
  const enableDevParam = searchParams.get(QueryParams.enableDev);

  // Update store when the beta features exist in the query param
  useEffect(() => {
    if (!enableBetaParam) {
      return;
    }

    const lowerCaseStr = enableBetaParam.toLowerCase();
    dispatch(setIsBetaTestingEnabled(lowerCaseStr === "true"));
  }, [dispatch, enableBetaParam]);

  // Update store when the alpha features exist in the query param
  useEffect(() => {
    if (!enableAlphaParam || !isUserInternal) {
      return;
    }

    const lowerCaseStr = enableAlphaParam.toLowerCase();
    dispatch(setIsAlphaTestingEnabled(lowerCaseStr === "true"));
  }, [dispatch, enableAlphaParam, isUserInternal]);

  // Update store when the dev features exist in the query param.
  // For this parameter, we also need to set `false` explicitly due to the early initialization in app-slice.ts
  // which does not know the user yet.
  useEffect(() => {
    // If it's still null, we don't know the user yet. We need to avoid setting `false` in this case,
    // since it could block the developer from visiting a protected page.
    if (isUserInternal === null) {
      return;
    }

    const lowerCaseStr = enableDevParam?.toLowerCase();
    dispatch(setIsDevModeEnabled(isUserInternal && lowerCaseStr === "true"));
  }, [dispatch, enableDevParam, isUserInternal]);

  return children as JSX.Element;
}
