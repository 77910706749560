import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { SelectRole } from "@components/role/select-role";
import { SelectRoleSubjectProps } from "@components/role/select-role-utils";

/** Renders a dropdown to select project role */
export function SelectProjectRole<
  T extends SphereDashboardAPITypes.IAssignmentProjectRole
>({
  selectedRole,
  onChange,
  isTableCell,
  disabledRoles = [],
}: SelectRoleSubjectProps<T>): JSX.Element {
  function onSelectRoleChange(role: CoreAPITypes.EUserProjectRole): void {
    // TODO: Type assertion will be fixed in https://faro01.atlassian.net/browse/ST-1902
    onChange(role as T);
  }
  return (
    <SelectRole
      label="Project role"
      roles={Object.values(CoreAPITypes.EUserProjectRole)}
      disabledRoles={[CoreAPITypes.EUserProjectRole.owner].concat(
        disabledRoles
      )}
      isTableCell={isTableCell}
      // TODO: Type assertion will be fixed in https://faro01.atlassian.net/browse/ST-1902
      selectedRole={selectedRole as CoreAPITypes.EUserProjectRole}
      onChange={onSelectRoleChange}
      link="projectRolesHelp"
    />
  );
}
