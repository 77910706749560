import { useEffect, useState } from "react";
import { useCoreApiClient } from "@api/use-core-api-client";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  fetchCompaniesAndWorkspaces,
  fetchCompanyContext,
  fetchCompanyFeatures,
  setSelectedSdbCompanyId,
  fetchCompanyCommunicationSettings,
} from "@store/sdb-company/sdb-company-slice";
import {
  selectedSdbCompanyIdSelector,
  sdbCompaniesSelector,
} from "@store/sdb-company/sdb-company-selector";
import { fetchCurrentUser, getLoggedInUser } from "@store/user/user-slice";
import { currentUserSelector } from "@store/user/user-selector";
import { useAppParams } from "@router/router-helper";
import { useAppNavigation } from "@hooks/navigation/use-app-navigation";
import { useLoadingSpinner } from "@context-providers/loading-spinner-provider";
import { IReactChildrenOnly } from "@custom-types/types";
import { setSelectedProjectId } from "@store/projects/projects-slice";
import { SelectWorkspacePage } from "@pages/workspace/select-workspace-page";
import { AuthenticatedRoute } from "@router/authenticated-route";
import { useToast } from "@hooks/use-toast";
import { useWorkspaceParams } from "@hooks/workspaces/use-workspace-params";
import { runtimeConfig } from "@src/runtime-config";
import { useTrackPageLoad } from "@hooks/use-track-page-load";
import { useCallback } from "react";
import { SdbCompany } from "@custom-types/sdb-company-types";
import { CoreAPIUtils } from "@stellar/api-logic";
import { StatusCodes } from "http-status-codes";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { isDevModeEnabledSelector } from "@store/app/app-selector";
import { getCompanyContext } from "@api/core-api-utils";

/**
 * Hooks that loads sdbCompanies (workspaces/companies) and redirect to main app page from root
 */
export function SdbCompanyLoader({
  children,
}: IReactChildrenOnly): JSX.Element {
  useTrackPageLoad();
  const appParams = useAppParams();

  const currentUser = useAppSelector(currentUserSelector);
  const selectedSdbCompanyId = useAppSelector(selectedSdbCompanyIdSelector);
  const sdbCompanies = useAppSelector(sdbCompaniesSelector);
  const isDevModeEnabled = useAppSelector(isDevModeEnabledSelector);

  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();
  const { navigateToProjects } = useAppNavigation();
  const { setLoadingSpinner, isLoadingSpinnerShowing } = useLoadingSpinner();
  const { showToast } = useToast();
  useWorkspaceParams();

  const [shouldShowSelectWorkspaceMenu, setShouldShowSelectWorkspaceMenu] =
    useState<boolean>(false);

  const [
    hasFetchedCompaniesAndWorkspaces,
    setHasFetchedCompaniesAndWorkspaces,
  ] = useState<boolean>(false);

  const { handleErrorWithPage } = useErrorContext();

  // Fetch all companies and workspaces only once on page load
  useEffect(() => {
    async function fetchCompaniesAndWorkspacesViaStore(): Promise<void> {
      // Error is handled in the store slice
      await dispatch(fetchCompaniesAndWorkspaces({ coreApiClient })).unwrap();
      setHasFetchedCompaniesAndWorkspaces(true);
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
    fetchCompaniesAndWorkspacesViaStore();
  }, [coreApiClient, dispatch]);

  // Handles loading spinner when fetching companies and workspaces
  useEffect(() => {
    if (isLoadingSpinnerShowing) {
      setLoadingSpinner(false);
    }
  }, [isLoadingSpinnerShowing, setLoadingSpinner]);

  // Fetches company features once on page load when user company is already defined
  useEffect(() => {
    async function fetchCompanyFeaturesViaStore(): Promise<void> {
      await dispatch(
        fetchCompanyFeatures({
          coreApiClient,
        })
      );
    }

    async function fetchCompanyContextViaStore(): Promise<void> {
      await dispatch(
        fetchCompanyContext({
          coreApiClient,
        })
      );
    }

    async function fetchCompanyCommunicationSettingsViaStore(): Promise<void> {
      await dispatch(
        fetchCompanyCommunicationSettings({
          coreApiClient,
        })
      );
    }

    if (selectedSdbCompanyId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      fetchCompanyFeaturesViaStore();
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      fetchCompanyContextViaStore();
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      fetchCompanyCommunicationSettingsViaStore();
    }
  }, [coreApiClient, dispatch, selectedSdbCompanyId]);

  // Fetch current user if not defined and when user company is already set
  useEffect(() => {
    async function fetchCurrentUserViaStore(): Promise<void> {
      if (selectedSdbCompanyId && !currentUser) {
        await dispatch(
          fetchCurrentUser({ coreApiClient, companyId: selectedSdbCompanyId })
        );
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
    fetchCurrentUserViaStore();
  }, [coreApiClient, currentUser, dispatch, selectedSdbCompanyId]);

  // Fetch logged in user
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
    dispatch(getLoggedInUser({ coreApiClient }));
  }, [coreApiClient, dispatch]);

  /**
   * Looks for the first company the user has access to and navigates to it.
   */
  const navigateToFirstCompany = useCallback(
    (firstCompany: SdbCompany) => {
    if (
      firstCompany &&
      window.location.hostname !== "localhost" &&
      // If the company url is from the old dashboard
      firstCompany.url.includes(runtimeConfig.urls.hbDashboardUrl)
    ) {
      // Makes sure to redirect to the url that it is set on the backend
      // based on the subscriptions for that workspace.
      // This replace of href is only used to navigate to the old dashboard.
      // For the new dashboard, the navigation is handled by the router.
      window.location.href = firstCompany.url;
    } else {
      navigateToProjects({ companyId: firstCompany.id });
    }
  },
  [navigateToProjects]);

  /** 
   * Handles the navigation to a company
   * @param companyIdFromParams - The company id to navigate to
   * @returns void
  */
  const handleCompanyNavigation = useCallback(async (companyIdFromParams: string): Promise<void> => {
    try {
      if (companyIdFromParams !== selectedSdbCompanyId) {
        // Attempt to fetch the company context by the companyIdFromParams
        await getCompanyContext(
          coreApiClient, companyIdFromParams, isDevModeEnabled
        );
        // Set the new selected company
        dispatch(setSelectedSdbCompanyId(companyIdFromParams));
      }
      // Hide selector workspace page
      setShouldShowSelectWorkspaceMenu(false);
    } catch (error) {
      // Either the user doesn't have access to the workspace (401) or the workspace doesn't exist with the given id (404)
      if (
        CoreAPIUtils.isResponseError(error) &&
        (error.status === StatusCodes.NOT_FOUND ||
         error.status === StatusCodes.UNAUTHORIZED)
      ) {
        showToast({
          message: "Access denied",
          description: (
            <>
              You don't have access to workspace <var>{companyIdFromParams}</var>. 
              Please select a different workspace or contact the workspace administrator.
            </>
          ),
          type: "error",
        });
        // Show selector workspace page
        setShouldShowSelectWorkspaceMenu(true);
      } else {
        handleErrorWithPage({
          id: `getCompanyContext-${Date.now().toString()}`,
          title:
            "Failed to fetch the company. Please reload the page to try again.",
          error: getErrorDisplayMarkup(error),
        });
      }
    }
  }, [coreApiClient, dispatch, isDevModeEnabled, selectedSdbCompanyId, showToast, handleErrorWithPage]);

   // Handles setting the selected company
   useEffect(() => {
      /**
       * If a companyId param exists in the URL, the following steps are taken:
       * 1. Attempt to navigate to that company
       * 2. Show error if user lacks access or company doesn't exist
       * 3. Display workspace selector if navigation fails
      */
      if (appParams.companyId) {
       void handleCompanyNavigation(appParams.companyId);
       return;
      } 

      // If a companyId param doesn't exist in the URL,
      // Wait for user companies to be fetched
      if (!hasFetchedCompaniesAndWorkspaces) {
        return;
      }

      // If the user has only one company then navigate to that company.
      if (sdbCompanies.length === 1) {
        navigateToFirstCompany(sdbCompanies[0]);
        return;
      } else {
        // If the user has zero or many companies then navigate to the workspace selection menu
        setShouldShowSelectWorkspaceMenu(true);
        return;
      }
  }, [
    appParams.companyId,
    sdbCompanies,
    hasFetchedCompaniesAndWorkspaces,
    handleCompanyNavigation,
    navigateToFirstCompany,
    shouldShowSelectWorkspaceMenu,
  ]);

  // React to projectId url param changes and keep the projectId in the store.
  useEffect(() => {
    dispatch(setSelectedProjectId(appParams.projectId ?? null));
  }, [appParams.projectId, dispatch]);

  return (
    <>
      {shouldShowSelectWorkspaceMenu && (
        <AuthenticatedRoute>
          <SelectWorkspacePage />
        </AuthenticatedRoute>
      )}
      {selectedSdbCompanyId && children}
    </>
  );
}
