import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { QueryParams } from "@router/route-params";

/**
 * State of the current App
 */
export type AppState = {
  /**
   * Flag to indicate if Analytics module is initialized.
   */
  isAnalyticsModuleInitialized: boolean;

  /**
   * Flag to hide certain functionalities that are not production ready in production environment.
   * This flag can be enabled by query param to test the functionalities in production environment.
   * i.e: enableBeta=true
   * For example, the flag can be used to hide the "Groups" section in production
   * if it is still not ready to be shown to the public.
   * !: This flag should be used only for functionalities that should not be secret, like launching a new device.
   * Instead for functionalities that could be shown to the users but that we feel they are still incomplete.
   */
  isBetaTestingEnabled: boolean;

  /**
   * Flag to hide beta functionalities. We should use this flag when the feature that we are implementing
   * is a prototype.
   */
  isAlphaTestingEnabled: boolean;

  /**
   * Flag to indicate if the app is running in development mode.
   * This adds ui elements that are not exposed to the customer.
   */
  isDevModeEnabled: boolean;
};

let enableDevAtPageLoad: string = "";
try {
  // Same parsing logic as in QueryParamsLoader, for consistency.
  const searchParams = new URLSearchParams(globalThis.location.search);
  enableDevAtPageLoad = (searchParams.get(QueryParams.enableDev) || "").toLowerCase();
} catch (error) {
  // eslint-disable-next-line no-console -- Unexpected, but not important.
  console.error("URLSearchParams parsing", error);
}

export const initialState: AppState = {
  isAnalyticsModuleInitialized: false,

  /** Beta testing is enabled in non-production environments or if the user has enabled with queryParam. */
  isBetaTestingEnabled: false,

  /** Alpha testing is enabled if the user has enabled with queryParam. */
  isAlphaTestingEnabled: false,

  /** Dev mode is enabled if the user has enabled it with queryParam. Initialize to avoid that it's false during page load. */
  isDevModeEnabled: enableDevAtPageLoad === "true",
};

/**
 * Slice to access state of general app meta data
 */
const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsAnalyticsModuleInitialized(state, action: PayloadAction<boolean>) {
      state.isAnalyticsModuleInitialized = action.payload;
    },

    setIsBetaTestingEnabled(state, action: PayloadAction<boolean>) {
      state.isBetaTestingEnabled = action.payload;
    },

    setIsAlphaTestingEnabled(state, action: PayloadAction<boolean>) {
      state.isAlphaTestingEnabled = action.payload;
    },

    setIsDevModeEnabled(state, action: PayloadAction<boolean>) {
      state.isDevModeEnabled = action.payload;
    },

    resetAppState: () => initialState,
  },
});

export const {
  setIsAnalyticsModuleInitialized,
  setIsBetaTestingEnabled,
  setIsAlphaTestingEnabled,
  setIsDevModeEnabled,
  resetAppState,
} = appSlice.actions;

export const appReducer = appSlice.reducer;
