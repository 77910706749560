import { useState } from "react";
import { useDebounce } from "@utils/time-utils";

/** Debounce interval in milliseconds */
export const SEARCH_DEBOUNCE_TIME = 300;

interface Props {
  /** The text to be searched for */
  searchText: string;
}

interface UseDebouncedSearchText {
  /** Whether it is debouncing to start the search */
  isDebouncingSearch: boolean;

  /** The debounced searched text */
  debouncedSearchText: string;
}

/** Returns the debounced search text and whether it is currently in debounce situation or not */
export function useDebouncedSearchText({
  searchText,
}: Props): UseDebouncedSearchText {
  /**
   * Flag whether it is debouncing (waiting) to start the call to search.
   * If true, it means that the subject will be searched again soon, but an indication
   * could be shown already (e.g. a loading spinner).
   */
  const [isDebouncingSearch, setIsDebouncingSearch] = useState(false);

  function debouncingStartCallback(): void {
    setIsDebouncingSearch(true);
  }

  function debouncingEndCallback(): void {
    // The time chosen is small enough for the user not to perceive it as a delay,
    // But large enough to wait for 3 repaint cycles in the browser.
    const WAIT_TIME = 50;
    // We do not immediately set isDebouncingSearch to false.
    // That way the spinner is not immediately hidden preventing a flickering effect in the spinner,
    // when another process wants to show it.
    // By doing this wait, the spinner animation should looks smoother,
    // otherwise it disappears for an instance and then appears again, looking glitchy.
    setTimeout(() => {
      setIsDebouncingSearch(false);
    }, WAIT_TIME);
  }

  /** Uses useDebounce hook to react to changes on the search input text */
  const debouncedSearchText = useDebounce(
    searchText,
    SEARCH_DEBOUNCE_TIME,
    debouncingStartCallback,
    debouncingEndCallback
  );

  return {
    isDebouncingSearch,
    debouncedSearchText,
  };
}
