import { APITypes, ApiClient, CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { assertValue } from "@utils/assert-utils";

// ------   Mocks   ------------------------------------------------------------------------------

/**
 * Mocked API responses when accessing a project of a foreign workspace with ?enableDev=true.
 * Currently it seems not required to include any special entries, empty arrays are enough.
 */
export const devModeMocks = {
  // GET /v3/company/:companyId/features
  features: [] as CoreAPITypes.IFeatureStateResponse[],

  // GET /v3/company/:companyId/context
  featuresAvailable: [] as SphereDashboardAPITypes.IFeature[],
  userRoles: [] as APITypes.EUserSubscriptionRole[],

  // GET /v3/dashboard/company/:companyId/member?types=user%2Cuser-group%2Cuser-invite
  members: [] as SphereDashboardAPITypes.ICompanyMemberBase[],

  // GET /v3/company/:companyId/user-groups
  teams: [] as SphereDashboardAPITypes.ITeam[],
};

// -----------------------------------------------------------------------------------------------

/** @returns The context of the given company, or fallback values if (error && devMode). */
export function getCompanyContext(
  coreApiClient: ApiClient,
  companyId: string,
  isDevModeEnabled: boolean
): Promise<SphereDashboardAPITypes.CompanyContext> {
  return coreApiClient.V3.SDB.getCompanyContext(companyId).catch((error) => {
    if (isDevModeEnabled) {
      return {
        featuresAvailable: devModeMocks.featuresAvailable,
        userRoles: devModeMocks.userRoles,
        // trial: Seems not needed for our purposes.
      };
    } else {
      throw error;
    }
  });
}

/** @returns The user profile for the given company, or for another company if (error && devMode). */
export async function getCurrentUserProfileInfos(
  coreApiClient: ApiClient,
  companyId: APITypes.CompanyId,
  isDevModeEnabled: boolean
): Promise<SphereDashboardAPITypes.ICompanyMemberBase> {
  try {
    const data = await coreApiClient.V3.SDB.getCurrentUserProfileInfos(
      companyId
    );
    return data;
  } catch (error) {
    if (isDevModeEnabled) {
      try {
        const companies = await coreApiClient.V3.SDB.getCompanies();
        const otherCompany =
          companies.find((c) => c.role === CoreAPITypes.EUserCompanyRole.companyExecutive) ||
          companies.find((c) => c.role === CoreAPITypes.EUserCompanyRole.companyViewer) ||
          assertValue(companies[0]);
        return await coreApiClient.V3.SDB.getCurrentUserProfileInfos(
          otherCompany.id
        );
      } catch (errorFallback) {
        // eslint-disable-next-line no-console -- Developer feature
        console.error("Error with fallback company", errorFallback);
      }
    }
    throw error;
  }
}
