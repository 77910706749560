import { Navigate } from "react-router-dom";
import { useAppSelector } from "@store/store-helper";
import { currentUserSelector } from "@store/user/user-selector";
import {
  GenerateForbiddenPageRouteProps,
  generateForbiddenPageRoute,
  useAppParams,
} from "@router/router-helper";
import { selectedProjectSelector } from "@store/projects/projects-selector";
import { PropsWithChildren, ReactNode } from "react";
import { requiredRolesCompanyLevel } from "@utils/access-control/company/company-access-control";
import { RequiredRoleCompanyLevelName } from "@utils/access-control/company/company-access-control-types";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { requiredPermissionsProjectLevel } from "@utils/permission-control/project-permission-control";
import { RequiredPermissionProjectLevelName } from "@utils/permission-control/project-permission-control-types";
import { useHasUserValidPermissionProjectLevel } from "@hooks/permission-control/use-has-user-valid-permission-project-level";

export interface SecuredRouteProps extends PropsWithChildren {
  /**
   * Defines the required company role that the user should have,
   * if the user matches any of the provided requiredCompanyRoles the component
   * will give the user access, otherwise will redirect to the forbidden page.
   */
  requiredRoleCompanyLevel?: RequiredRoleCompanyLevelName;

  /**
   * Defines the required project role that the user should have in the selected project,
   * if the user matches any of the provided requiredProjectRoles the component
   * will give the user access, otherwise will redirect to the forbidden page.
   */
  requiredPermissionProjectLevel?: RequiredPermissionProjectLevelName;

  /** Whether the content should be hidden */
  isHidden?: boolean;

  /**
   * Optional content to show while the tab is loading.
   * This is normally used when the permissions are fetched asynchronously,
   * otherwise an empty page is shown meanwhile.
   */
  loadingContent?: ReactNode | null;
}

/**
 * Returns a Node module that returns to the forbidden page,
 * including information in the url like the required missing roles.
 *
 * @returns A Node module that once instantiated redirects to forbidden page.
 */
function redirectToForbiddenRoute({
  companyId,
  requiredCompanyRoles,
  requiredPermissions,
  requiredCompanySubscriptions,
  userId,
}: GenerateForbiddenPageRouteProps): JSX.Element {
  const forbiddenRoute = generateForbiddenPageRoute({
    companyId,
    requiredCompanyRoles,
    requiredPermissions,
    requiredCompanySubscriptions,
    userId,
  });
  return <Navigate to={forbiddenRoute} />;
}

/**
 * Defines a component to secure a route based on the user company role.
 * The required company role defines an array of company roles that the
 * user should have one of them, and if it doesn't match that criteria
 * it is redirected to a forbidden page.
 * This component can be extended to add more checks like project role
 * user permissions or company features.
 */
export function SecuredRoute({
  children,
  requiredRoleCompanyLevel,
  requiredPermissionProjectLevel,
  isHidden,
  loadingContent = null,
}: SecuredRouteProps): JSX.Element | null {
  const currentUser = useAppSelector(currentUserSelector);
  const selectedProject = useAppSelector(selectedProjectSelector);
  const { companyId, projectId, memberId } = useAppParams();
  const { hasUserPermissionCompanyLevel } = useHasUserValidRoleCompanyLevel();
  const { hasUserPermissionProjectLevel } =
    useHasUserValidPermissionProjectLevel();

  if (!currentUser || (projectId && !selectedProject)) {
    // Wait until current user, selected company
    // and selected project (if project id was found in the url) are defined.
    // Meanwhile do not redirect or give access, the application will take care
    // to show the loading content.
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{loadingContent}</>;
  }

  // First check whether the route requires roles in the company level side.
  if (
    requiredRoleCompanyLevel &&
    requiredRoleCompanyLevel in requiredRolesCompanyLevel &&
    // If it does require roles, check for the access level and redirect if user does not
    // have the needed roles.
    !hasUserPermissionCompanyLevel({
      roleName: requiredRoleCompanyLevel,
      memberId,
    })
  ) {
    const requiredCompanyRoles =
      requiredRolesCompanyLevel[requiredRoleCompanyLevel].companyRoles ?? [];
    const requiredCompanySubscriptions =
      requiredRolesCompanyLevel[requiredRoleCompanyLevel]
        .companySubscriptionRoles ?? [];

    return redirectToForbiddenRoute({
      companyId,
      requiredCompanyRoles,
      requiredCompanySubscriptions,
      userId: currentUser?.identity,
    });
  }

  // If didn't require permissions on the company level, check for permissions
  // on the project level.
  // for the members tab, 'Enterprise viewers' can view all project members, so we need to check companyRole here.
  if (requiredPermissionProjectLevel && requiredPermissionProjectLevel in requiredPermissionsProjectLevel) {
    const isMembersTab = requiredPermissionProjectLevel === RequiredPermissionProjectLevelName.canViewAllProjectMembers;
    const hasProjectPermission = hasUserPermissionProjectLevel({
      permissionName: requiredPermissionProjectLevel,
      project: selectedProject,
    });

    const hasCompanyPermission = hasUserPermissionCompanyLevel({
      roleName: RequiredRoleCompanyLevelName.canViewAllCompanyUsers,
      memberId,
    });

    const isAllowed = isMembersTab ? (hasProjectPermission || hasCompanyPermission) : hasProjectPermission;

    if (!isAllowed) {
      const requiredPermissions = requiredPermissionsProjectLevel[requiredPermissionProjectLevel].permissions;
      return redirectToForbiddenRoute({
        companyId,
        requiredPermissions,
        userId: currentUser?.identity,
      });
    }
  }

  if (isHidden) {
    return redirectToForbiddenRoute({
      companyId,
      userId: currentUser?.identity,
    });
  }

  // If either the route didn't require permissions or the user has enough
  // permissions, show component.
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
