import { RootState } from "@store/store-helper";
import {
  filterProjects,
  getProjectArchivingState,
  getProjectManager,
  getProjectManagerName,
  isProjectDemo,
  MIN_CHARACTERS_FOR_SEARCH,
} from "@utils/project-utils";
import {
  ProjectArchivingState,
  ProjectSettings,
  SdbProject,
} from "@custom-types/project-types";
import { projectsAdapter } from "@store/projects/projects-slice";
import {
  APITypes,
  CoreAPITypes,
  SphereDashboardAPITypes,
} from "@stellar/api-logic";
import { searchSelector } from "@store/ui/ui-selector";
import { MemberTypes } from "@custom-types/member-types";
import { createSelector, EntityId } from "@reduxjs/toolkit";
import { selectedGroupProjectsSelector } from "@store/groups/groups-selector";
import { hasUserValidPermissionProjectLevel } from "@utils/permission-control/project-permission-control";
import { RequiredPermissionProjectLevelName } from "@utils/permission-control/project-permission-control-types";
import { ProjectsListType, ProjectsState } from "@store/projects/projects-slice-types";
import { getListType } from "@store/projects/projects-slice-utils";

/**
 * @returns projects filtered by the given archiving state and the current search text.
 * @param archivingState Identifier of the archiving state.
 */
export function filteredProjectsSelector(
  archivingState: ProjectArchivingState
): (state: RootState) => SdbProject[] {
  return createSelector(
    [
      projectsSelector,
      searchSelector,
    ],
    (projects, search) => {
      let filteredProjects = projects.filter((project) => archivingState === getProjectArchivingState(project.archivingState));

      // The backend has the requirement of a minimum char length to search for projects. This is why
      // we will only filter by search if the search length has at least the minimum required.
      const searchText = search.debouncedSearchText;
      if (searchText.length >= MIN_CHARACTERS_FOR_SEARCH) {
        filteredProjects = filterProjects(projects, {
          searchText,
        });
      }
      return filteredProjects;
    }
  );
}

/**
 * Returns all projects
 */
export const projectsSelector: (state: RootState) => SdbProject[] =
  createSelector(
    (state: RootState) => state.projects,
    (projectsState) => {
      return projectsAdapter.getSelectors().selectAll(projectsState);
    }
  );

/**
 * Returns all projects mapped by their id
 */
export const projectsMapByIdSelector: (
  state: RootState
) => Map<APITypes.ProjectId, SdbProject> = createSelector(
  (state: RootState) => state.projects.entities,
  (projects: Record<EntityId, SdbProject>) => {
    return new Map(Object.entries(projects));
  }
);

/**
 * Returns the ids of all projects
 */
export const projectIdsSelector: (state: RootState) => APITypes.ProjectId[] =
  createSelector(
    (state: RootState) => state.projects,
    (projectsState) => {
      return projectsState.ids;
    }
  );

/** Returns the active projects belong to the selected group */
export const activeProjectsOfSelectedGroupSelector: (
  state: RootState
) => SdbProject[] = createSelector(
  [
    selectedGroupProjectsSelector,
    filteredProjectsSelector(ProjectArchivingState.active),
  ],
  (groupProjects, activeProjects) => {
    const groupProjectsIds = groupProjects.map((project) => project.id);
    return activeProjects.filter(({ id }) => groupProjectsIds.includes(id));
  }
);

/**
 * Returns true if a given project can be archived.
 *
 * @param project
 */
export function isProjectArchivingAllowedSelector(
  project: SdbProject
): (state: RootState) => boolean {
  const getIsProjectAllowedToBeArchived = isFeatureEnabledForProjectSelector(
    project.id,
    APITypes.EUserSubscriptionRole.projectArchive
  );

  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return (
        isProjectActiveSelector(project.id)(state) &&
        getIsProjectAllowedToBeArchived(state)
      );
    }
  );
}

/**
 * Returns true if a given project can be unarchived.
 *
 * @param project
 */
export function isProjectUnarchivingAllowedSelector(
  project: SdbProject
): (state: RootState) => boolean {
  const getIsProjectAllowedToBeUnarchived = isFeatureEnabledForProjectSelector(
    project.id,
    APITypes.EUserSubscriptionRole.projectUnarchive
  );

  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return (
        projectArchivingStateSelector(project.id)(state) ===
          ProjectArchivingState.archived &&
        getIsProjectAllowedToBeUnarchived(state) &&
        project.archivingState !== APITypes.ArchivingState.ARCHIVED_DOWNLOADED
      );
    }
  );
}

/**
 * Returns the fetching properties of the projects slice.
 */
export const fetchingProjectsFlagsSelector: (
  state: RootState
) => ProjectsState["fetching"] = createSelector(
  (state: RootState) => state.projects,
  (projectsState) => projectsState.fetching
);

/**
 * Returns true if a given a project is currently ongoing a process.
 *
 * @param projectId
 */
export function isProjectProcessingSelector(
  projectId: APITypes.ProjectId
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.projects.fetching.processingProjects[projectId];
    }
  );
}

/**
 * Get project details by providing the projectId
 */
export function getProjectByIdSelector(
  projectId: APITypes.ProjectId | undefined
): (state: RootState) => SdbProject | null {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      if (!projectId) {
        return null;
      }
      return (
        projectsAdapter.getSelectors().selectById(state.projects, projectId) ??
        null
      );
    }
  );
}

/**
 * Get the selected project using the selected project Id and finding it on the store,
 * returns null if there's no projectId or if the project could not be found in the store.
 */
export const selectedProjectSelector: (state: RootState) => SdbProject | null =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const selectedProjectId = state.projects.selectedProject.id;
      if (!selectedProjectId) {
        return null;
      }

      return (
        projectsAdapter
          .getSelectors()
          .selectById(state.projects, selectedProjectId) ?? null
      );
    }
  );

/**
 * Get the selected project ID
 */
export const selectedProjectIdSelector: (
  state: RootState
) => APITypes.ProjectId | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.projects.selectedProject.id;
  }
);

/**
 * Returns the project members
 */
export const projectMembersSelector: (
  state: RootState
) => SphereDashboardAPITypes.IProjectMemberBase[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    if (!state.projects.selectedProject.id) {
      return [];
    }

    const selectedProject = selectedProjectSelector(state);

    return selectedProject?.members ?? [];
  }
);

/**
 * Get project member with the provided role
 */
export function projectMembersByRoleSelector(
  userRole: CoreAPITypes.EUserProjectRole
): (state: RootState) => SphereDashboardAPITypes.IProjectMemberBase[] {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const selectedMembers = projectMembersSelector(state);

      return selectedMembers.filter(({ role }) => role === userRole);
    }
  );
}

/**
 * Get project member with the provided role
 */
export function isFeatureEnabledForProjectSelector(
  projectId: APITypes.ProjectId,
  feature: APITypes.EUserSubscriptionRole
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const project = getProjectByIdSelector(projectId)(state);
      if (!project || project.features === null) {
        return false;
      }

      return project.features[feature].enabled;
    }
  );
}

/**
 * Returns the archiving state of a project
 */
export function projectArchivingStateSelector(
  projectId: APITypes.ProjectId
): (state: RootState) => ProjectArchivingState | null {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const project = getProjectByIdSelector(projectId)(state);
      if (!project) {
        return null;
      }

      return project.archivingState === APITypes.ArchivingState.UNARCHIVED
        ? ProjectArchivingState.active
        : ProjectArchivingState.archived;
    }
  );
}

/**
 * Returns whether the project is active or not
 */
export function isProjectActiveSelector(
  projectId: APITypes.ProjectId
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return (
        projectArchivingStateSelector(projectId)(state) ===
        ProjectArchivingState.active
      );
    }
  );
}

/**
 * Returns whether the project is archived or not
 */
export function isProjectArchiveSelector(
  projectId: APITypes.ProjectId
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return (
        projectArchivingStateSelector(projectId)(state) ===
        ProjectArchivingState.archived
      );
    }
  );
}

/**
 * Returns true if selected project is editable
 * Projects can be editable if: User is allowed to edit them and they are active
 */
export function isSelectedProjectEditableSelector(): (
  state: RootState
) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const selectedProject = selectedProjectSelector(state);

      if (selectedProject) {
        const hasUserPermissionToEditProject =
          hasUserValidPermissionProjectLevel({
            permissionName:
              RequiredPermissionProjectLevelName.canEditProjectDetails,
            selectedProject,
          });

        const isProjectActive =
          getProjectArchivingState(selectedProject.archivingState) ===
          ProjectArchivingState.active;

        const isDemoProject = isProjectDemo(selectedProject);

        return (
          hasUserPermissionToEditProject && isProjectActive && !isDemoProject
        );
      }

      return false;
    }
  );
}

/**
 * Gets the project manager and its name for the selected project.
 *
 * @returns An object including the project manager of the project,
 * and the prettified version of the name.
 */
export const selectedProjectManagerSelector: (state: RootState) => {
  user: MemberTypes | null;
  name: string;
} = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const selectedProject = selectedProjectSelector(state);
    const user = selectedProject ? getProjectManager(selectedProject) : null;
    const name = selectedProject ? getProjectManagerName(selectedProject) : "";
    return { user, name };
  }
);

/**
 * @returns the cursor to fetch the next page of the list of projects
 * @param archivingState Archiving state of the projects
 */
export function nextPageCursorSelector(
  listType: ProjectsListType
): (state: RootState) => string | null {
  return createSelector(
    (state: RootState) => state.projects.lists,
    (lists) => {
      return lists[listType].nextPageCursor;
    }
  );
}

/**
 * Returns the project context of the selected project.
 */
export const selectedProjectContextSelector: (
  state: RootState
) => SphereDashboardAPITypes.IProjectContextResponse | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.projects.selectedProject.context;
  }
);

/**
 * Returns the settings of the selected project.
 */
export const selectedProjectSettingsSelector: (
  state: RootState
) => ProjectSettings | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.projects.selectedProject.settings;
  }
);

/**
 * @returns whether the annotations of the current project should be displayed for viewers
 */
export const shouldDisplayMarkupsForViewersSelector: (
  state: RootState
) => boolean = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const settings = selectedProjectSettingsSelector(state);
    return settings?.shouldDisplayMarkupsForViewers ?? false;
  }
);

/**
 * @returns the type of projects list by archiving state
 * @param archivingState Archiving state of the projects
 */
export function listTypeSelector(
  archivingState: ProjectArchivingState
): (state: RootState) => ProjectsListType {
  return createSelector(
    searchSelector,
    (search) => getListType(archivingState, search.debouncedSearchText)
  );
}

/**
 * @returns whether the first page of a list of projects has been fetched.
 * @param listType Type of the list of projects
 */
export function hasFetchedFirstPageSelector(
  listType: ProjectsListType
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state.projects.lists,
    (lists) => {
      return lists[listType].hasFetchedFirstPage;
    }
  );
}
